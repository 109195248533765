
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

import QuoteRequestDropZone from "./QuoteRequestDropZone.vue";
import ViewQuoteRequestDocuments from "@/components/partials/quote-request/ViewQuoteRequestDocuments.vue";
import QuotesService from "@/core/services/car/QuotesService";
import { notif } from '@/store/stateless/store';

import {
  DrawerComponent
} from "@/assets/ts/components/index";

interface IQuoteBodyParam {
  quote_request_id: any;
  notes: any;
  doc_id: any;
}

interface IPayload {
  per_page: number | string;
  page: number;
}

export default defineComponent({
  name: "quote-request-document-modal",
  components: {
    ViewQuoteRequestDocuments, QuoteRequestDropZone
  },
  props: {
    quoteRequestDocuments: Array,
    insuranceProvidersList: Array,
    showCategory: String,
    showTempDocs: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentQuoteReqId: Number,
    selectedQuoteReqStatus: Boolean
  },

  setup(props) {
    const store = useStore();

    let quoteBodyParam = {} as IQuoteBodyParam;
    let bodyPayloads = ref<IPayload>({
      per_page: 50,
      page: 1,
    });
    let notes = ref<string>("");


    let loading = computed(() => {
      return store.getters.getQrLoading;
    });

    let quoteError = computed(() => {
      return store.getters.getQuotesErrors;
    });

    const selectedPaymentDocId = ref(0);

    const approveQuoteRequest = (quoteReqId) => {
      quoteBodyParam.quote_request_id = quoteReqId
      const data = {
        quote_request_id: quoteReqId,
        update_notes: props.quoteRequestDocuments
      }
      Promise.all([
        QuotesService.approveQuoteRequest(data),
      ]).then((data) => {
        QuotesService.callingQuoteRequestListing(bodyPayloads.value),
        DrawerComponent.hideAll();
      }).catch((error) => { console.log(error); });
    }

    const selectedPaymentValue = () => {
      
      // props.quoteRequestDocuments.forEach((quoteRequestDocument) => {
      //   //quoteDocsArray.push(quoteDoc.id);
      //   if(quoteRequestDocument.payment_requested == 1) {
      //     selectedPaymentDocId.value = valArr.doc_id;

      //   }
      // });
    }

    const sendPaymentLink = (quoteReqId, selectedPaymentDocId) => {
      const payment:any = props.quoteRequestDocuments?.find((x: any) => x.payment_requested === 1);
      quoteBodyParam.quote_request_id = quoteReqId;
      quoteBodyParam.doc_id = payment.id;
      quoteBodyParam.notes = notes.value;
      if(notes.value == "") {
        notif.simple('Please Note', 'warning', 'Please enter a payment link', 0);
        return;
      }
      Promise.all([
        QuotesService.sendingPaymentLink(quoteBodyParam),
      ]).then((data) => {
        QuotesService.callingQuoteRequestListing(bodyPayloads.value),
        DrawerComponent.hideAll();
        notes.value = "";
      }).catch((error) => { console.log(error); });
    }

    return {
      approveQuoteRequest,
      loading,
      quoteError,
      notes,
      sendPaymentLink,
      selectedPaymentDocId,
      selectedPaymentValue,
    };
  },
});
