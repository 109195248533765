<template>
  <div>
    <div ref="dropRef" class="dropzone">
    <!-- <div class="dropzone preview-container"></div> -->
    </div>
  </div>
  <!-- {{ leadId }} -- {{ customerId }} -- {{ policyId }} -- {{ categories }} -->
  <!-- <div class="table-responsive h-100" v-show="false">
    <div class="mt-10">
      <table
        class="
          table table-hover table-bai-striped table-row-bordered
          border
          gy-7
          gs-7
        "
        v-if="tempDocs"
      >
        <thead class="">
          <tr
            class="
              bg-bai-table-header
              text-gray-700 text-start
              fw-bolder
              fs-6
              gs-0
              cursor-pointer
            "
          >
            <th class="min-w-100px">Sr. #</th>
            <th class="min-w-100px">Preview</th>
            <th class="min-w-150px">Document Type</th>
            <th class="min-w-150px">File Name</th>

            <th class="min-w-150px">
              <div>Uploaded On</div>
              <div>Uploaded By</div>
            </th>
            <th class="min-w-150px">Action</th>
          </tr>
        </thead>
        <tbody class="fw-bold text-gray-600">
          <tr
            class="odd bg-hover-light"
            v-for="(doc, index) in tempDocs"
            :key="index"
          >
            <td>
              {{ index + 1 }}
            </td>
            <td>
              <img :src="doc.file_path" height="50" width="50" />
            </td>
            <td>
              <select
                class="form-select"
                aria-label="Select document type"
                @change="updateDocType($event, doc.id)"
              >
                <option value="-1" selected>Select Document Type</option>
                <option
                  v-for="types in docTypes"
                  :key="types.value"
                  :value="types.value"
                  :selected="doc.type == types.value"
                >
                  {{ types.label }}
                </option>
              </select>
            </td>
            <td>
              {{ doc.original_filename }}
            </td>
            <td>
              <div>
                {{ doc.created_at }}
              </div>
              <span v-if="doc.created_by_skye_user != null">
                {{ doc.created_by_skye_user.email }}
              </span>
              <span v-if="doc.created_by_customer != null">
                {{ doc.created_by_customer.email }}
              </span>
              <span
                v-if="
                  doc.created_by_skye_user == null &&
                  doc.created_by_customer == null
                "
              >
                Not found
              </span>
            </td>
            <td>
              <a
                href="javascript:void(0);"
                @click="deleteTempDocument(doc.id)"
                title="Delete Document"
                class="btn-outline-brand"
              >
                <i class="la la-trash fs-2x"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> -->
</template>

<script>
import { ref, onMounted, defineComponent, computed } from "vue";
import { Dropzone } from "dropzone";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import JwtService from "@/core/services/JwtService";
import MasterService from "@/core/services/car/MasterService";
import QuotesService from "@/core/services/car/QuotesService";



export default defineComponent({
  name: "Dropzone",
  props: {
    currentQuoteReqId: Number,
  },
  setup(props) {
    const dropRef = ref(null);
    let docBodyParam = {};

    let myDropzone = "";
    
    const store = useStore();
    const route = useRoute();
    const customPreview = `
      <div class="dz-preview dz-processing dz-image-preview dz-complete">
        <div class="dz-image">
          <img data-dz-thumbnail>
        </div>
        <div class="dz-details">
          <div class="dz-size"><span data-dz-size></span></div>
            <div class="dz-filename"><span data-dz-name></span></div>
          </div>
          <div class="dz-progress">
            <span class="dz-upload" data-dz-uploadprogress></span>
          </div>
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
          <div class="dz-success-mark">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2071 29.7929L14.2929 25.7071C14.6834 25.3166 15.3166 25.3166 15.7071 25.7071L21.2929 31.2929C21.6834 31.6834 22.3166 31.6834 22.7071 31.2929L38.2929 15.7071C38.6834 15.3166 39.3166 15.3166 39.7071 15.7071L43.7929 19.7929C44.1834 20.1834 44.1834 20.8166 43.7929 21.2071L22.7071 42.2929C22.3166 42.6834 21.6834 42.6834 21.2929 42.2929L10.2071 31.2071C9.81658 30.8166 9.81658 30.1834 10.2071 29.7929Z" />
            </svg>  
          </div>  
          <div class="dz-error-mark">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.2929 20.2929L19.2071 13.2071C18.8166 12.8166 18.1834 12.8166 17.7929 13.2071L13.2071 17.7929C12.8166 18.1834 12.8166 18.8166 13.2071 19.2071L20.2929 26.2929C20.6834 26.6834 20.6834 27.3166 20.2929 27.7071L13.2071 34.7929C12.8166 35.1834 12.8166 35.8166 13.2071 36.2071L17.7929 40.7929C18.1834 41.1834 18.8166 41.1834 19.2071 40.7929L26.2929 33.7071C26.6834 33.3166 27.3166 33.3166 27.7071 33.7071L34.7929 40.7929C35.1834 41.1834 35.8166 41.1834 36.2071 40.7929L40.7929 36.2071C41.1834 35.8166 41.1834 35.1834 40.7929 34.7929L33.7071 27.7071C33.3166 27.3166 33.3166 26.6834 33.7071 26.2929L40.7929 19.2071C41.1834 18.8166 41.1834 18.1834 40.7929 17.7929L36.2071 13.2071C35.8166 12.8166 35.1834 12.8166 34.7929 13.2071L27.7071 20.2929C27.3166 20.6834 26.6834 20.6834 26.2929 20.2929Z" />
          </svg>  
        </div>
      </div>
    `;

    onMounted(() => {
      if (dropRef.value !== null) {

        myDropzone = new Dropzone(dropRef.value, {
          previewTemplate: customPreview,
          addRemoveLinks: true,
          // disablePreviews: true,
          maxFilesize: 5,
          dictDefaultMessage: 'Click to Attach files or drop files here. Valid document types that can be uploaded are pdf only',
          acceptedFiles: ".pdf",
          url: process.env.VUE_APP_API_URL + "skye/documents/add-quote-request-document",
          method: "POST",
          dictRemoveFile: "Remove",
          dictInvalidFileType: 'Maximum allowed file size is 5MB, Allowed file types: pdf, jpeg, jpg and png',
          dictFileTooBig: 'Maximum allowed file size is 5MB, Allowed file types: pdf, jpeg, jpg and png',
          dictResponseError: 'Maximum allowed file size is 5MB, Allowed file types: pdf, jpeg, jpg and png',

          headers: { Authorization: `Bearer ${JwtService.getToken()}` },
        });

        myDropzone.on("processing", () => {
            myDropzone.options.params = {
              quote_request_id: props.currentQuoteReqId
            };
        });

        myDropzone.on("complete", (file) => {
          getQuoteRequestDocument(props.currentQuoteReqId);
        });

        myDropzone.on("success", (file) => {
          if (file.previewElement != null && file.previewElement.parentNode != null) {
            myDropzone.removeFile(file)
          }
        })

        myDropzone.on("error", (file, message) => {
          if (file.previewElement) {
            file.previewElement.classList.add("dz-error");
            if (typeof message !== "string" && message.error) {
              message = 'Maximum allowed file size is 3MB, Allowed file types: pdf, jpeg, jpg and png';
            }
            for (let node of file.previewElement.querySelectorAll(
              "[data-dz-errormessage]"
            )) {
              node.textContent = message;
            }
          }
        })
        
      }
    });

    const getQuoteRequestDocument = (quoteReqId) => {
      docBodyParam.quote_request_id = quoteReqId;
      Promise.all([
        QuotesService.callingQuoteRequestDocs(docBodyParam),
      ]).then((data) => {
        console.log('nothing');
      });
    };
    
    return {
      dropRef,
    };
  },
});
</script>

<style scoped>
.custom-dropzone {
  border-style: dashed;
  border-width: 3px;
  padding: 20px;
}

.preview-container {
  border: none;
}
</style>
