

import { defineComponent, ref, computed, onBeforeMount, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import ViewTempDocuments from "@/components/partials/policy/ViewTempDocuments.vue";
import QuotesService from "@/core/services/car/QuotesService";
import PolicyService from "@/core/services/car/PolicyService";
import QuoteRequestDocumentModal from "@/components/modals/forms/QuoteRequestDocumentModal.vue";
import { statusModal } from "@/store/stateless/store";
import { Mutations } from "@/store/enums/StoreEnums";
import MasterService from "@/core/services/car/MasterService";

export interface IInsurancePolicies {
  id: number
  insurance_provider_id: number
  policy_name: string
  short_name: string
  policy_type: number
  policy_sub_type: number
  created_at: string
  updated_at: string
  status: number
  policy_type_text: string
}

export default defineComponent({
  // component name
  name: "quote-requests",
  components: {
    QuoteRequestDocumentModal, ViewTempDocuments
  },
  props: {
    selectedQuoteReqStatus : Boolean,
    openUploadTab : {
      type : Boolean,
      required : false,
      default : false
    }
  },
  data() {
    // constants
  },
  setup() {
    const store = useStore();

    let policyDocuments = computed(() => {
      return store.getters.getTempPolicyDocs;
    });

    let quoteRequestDocuments = computed(() => {
      return store.getters.getQuoteRequestDocument;
    });

    let insuranceProvidersList = computed(() => {
      return store.getters.getInsuranceProvidersList;
    });

    let currentQuoteReqId = computed(() => {
      return store.getters.getQuoteRequestId;
    });


    const currentPage = ref(1);

    const setLoadingTrue = () => {
      store.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, true);
      store.commit(Mutations.SET_QUOTE_REQUEST_DOCS, []);
      
    }

    const inst = getCurrentInstance()
    
    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeTime = () => {
        const viewDoc:any = inst?.refs?.viewDoc
        viewDoc.click()
    }

    const uploadTime = () => {
        const uploadDoc:any = inst?.refs?.uploadDoc
        uploadDoc.click()
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    const activeLink = ref(true)

    return {
      uploadTime,
      closeTime,
      activeLink,
      closeRef,
      closeModal,
      setLoadingTrue,
      currentPage,
      policyDocuments,
      statusModal,
      quoteRequestDocuments,
      insuranceProvidersList,
      currentQuoteReqId,
    };
  }
});
