
import { defineComponent, onMounted, ref, computed } from "vue";
import PolicyService from "@/core/services/car/PolicyService";
import { useStore } from "vuex";
import MasterService from "@/core/services/car/MasterService";
import ReusableModal from '@/components/modals/forms/ReusableModal.vue';

interface DeleteDocParam {
  document_id: number;
}
interface PolicyBodyParam {
  policy_id: any;
}
interface DownloadDocBodyParam{
    temp:  Array<any>;
    cancellation: Array<any>;
}
export default defineComponent({
  name: "policy-documents-partials",
  components: {
    ReusableModal
  },
  props: {
    policyId: Number,
    categories: String,
    tempDocs: Array,
    showHeader: Boolean,
    leadId: Number,
    showAllDownload: {
      type: Boolean,
      required: false,
      default: true
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    showDeleteOnUpload: {
      type: Boolean,
      required: false,
      default: false
    },
    showDownload: {
      type: Boolean,
      required: false,
      default: false
    },
    isPolicyOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    withEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
    policy: {
      type: Object,
      required: false,
    },
    documentType: String
  },
  data() {
    return {};
  },
  setup(props, context) {
    const store = useStore();
    let deleteDocParam = {} as DeleteDocParam;
    let policyBodyParam = {} as PolicyBodyParam;
    let downloadDocBodyParam = {} as DownloadDocBodyParam;
    let docTypes = ref("");
    // onMounted(() => {
    //   Promise.all([
    //     MasterService.getDocumentTypes({
    //       categories: props.categories,
    //       document_type: props.documentType ? props.documentType : '',
    //     }),
    //   ]).then((data) => {
    //     docTypes.value = store.getters.getMasterDocTypes;
    //   });
    // });

    onMounted(() => {
      MasterService.getDocumentTypes({
                categories: 'standard'
            })
      MasterService.getCanxDocumentTypes({
            categories: 'cancellation'
      })    
    });

    let getPolicyDomStatus = computed(() => {
      return store.getters.getPolicyDocumentDomStatus;
    });
    docTypes = computed(() => {
      const data = (props.categories === 'cancellation') ? store.getters.getCanxDocumentTypes 
                                                         : store.getters.getMasterDocTypes;



      if(props.policy && 
        props.policy.last_status === 4 && 
        props.policy.car_lead_quote && 
        !props.policy.car_lead_quote.quote_ref_no.startsWith('BAI-CI-') && 
        props.isPolicyOnly === true) {
        return data.filter(x => x.value === 1)
      }

      return data
    });

    const assignDeleteDocument = (docId, policyId) => {
      deleteDocParam.document_id = docId;
      policyBodyParam.policy_id = policyId;
    };
    const deleteDocument = () => {
      Promise.all([PolicyService.deleteDocument(deleteDocParam)]).then(
        (data) => {
          PolicyService.getPolicyDetails(policyBodyParam);
          PolicyService.getPolicyDocuments(policyBodyParam);
          PolicyService.getAllDocuments(policyBodyParam);
        }
      );
    };
    const updateDocType = (event, docId) => {
      Promise.all([
        PolicyService.updateDocType({
          document_id: docId,
          type: event.target.value
        })
      ]).then((data) => {
        // getTempDocument();
      });
      context.emit("resetError");
    };
    const getTempDocument = () => {
      // console.log("5");
      // 
      Promise.all([
        PolicyService.getTempPolicyDocuments(
          {
            policy_id: props.policyId,
            lead_id: policy_list.value.lead_id || props.leadId,
            categories: props.categories
          },
          false
          ),
        PolicyService.getTempPolicyCancelDocuments(
          {
            policy_id: props.policyId,
            lead_id: policy_list.value.lead_id || props.leadId,
            categories: props.categories
          },
          false
        ),
      ]).then((data) => {
        // tempDocs.value = store.getters.getTempPolicyDocs;
      });
    };
    const deleteTempDocument = (docId) => {
      isLoadingData.value = true;
      Promise.all([
        PolicyService.deleteTempDocument({
          document_id: docId
        })
      ]).then((data) => {
        close();
        getTempDocument();
      });
    };

    const policy_list = computed(() => {
      return store.state.PolicyModule.leadDocuments;
    });

    const selected = ref();

    const openMouseEnter = ref(false);

    // const open = (e) => {
    //   selected.value = e;
    //   openMouseEnter.value = !openMouseEnter.value;
    // };

    function open(value: string) {
      window.open(value)
    }

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const ApiPath = process.env.VUE_APP_API_URL;

    const itemData = ref(null);
    const isLoadingData = ref(false);
    const isOpen = ref(false);
    const OpenDelete = (id) => {
      itemData.value = id;
      isOpen.value = true;
      focusable.value.toFirst()
    }

    const close = () => {
      itemData.value = null;
      isOpen.value = false;
      isLoadingData.value = false;
    }

    const loading = computed(() => {
      return store.getters.getLoadingTempDocument;
    })

    const downloadZip = (tempDocs) => {
      let tempDocsArray = Array<any>();
        tempDocs.forEach((tempDoc) =>{
          tempDocsArray.push(tempDoc.id);
      });

      const propsPolicy    = props?.policy; 
      const canxApprovedUw = ((typeof(propsPolicy?.cancellation_underwriter_approved_by) !== 'undefined') && (propsPolicy.cancellation_underwriter_approved_by !== null)) 
                                ? propsPolicy.cancellation_underwriter_approved_by.toString() 
                                : '';

      if((canxApprovedUw.toString().length > 0) && (props?.categories === 'cancellation')){
        downloadDocBodyParam.temp = [];
        downloadDocBodyParam.cancellation = tempDocsArray;
      }else{
        downloadDocBodyParam.temp = tempDocsArray;
        downloadDocBodyParam.cancellation = [];
      }
      PolicyService.downloadZip(downloadDocBodyParam);
    };
    const getTextWidth = (text, font) => {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };
    const focusable = ref()

    return {
      focusable,
      loading,
      close,
      itemData,
      isOpen,
      isLoadingData,
      OpenDelete,
      ApiPath,
      open,
      selected,
      getPolicyDomStatus,
      openMouseEnter,
      policy_list,
      assignDeleteDocument,
      deleteDocument,
      docTypes,
      updateDocType,
      deleteTempDocument,
      userPermissions,
      downloadZip,
      getTextWidth,
    };
  }
});
