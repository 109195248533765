
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import PolicyDocumentModal from "@/components/modals/forms/PolicyDocumentModal.vue";
import QuotesService from "@/core/services/car/QuotesService";
import PolicyService from "@/core/services/car/PolicyService";
import QuoteRequestDocumentModal from "@/components/modals/forms/QuoteRequestDocumentModal.vue";
import UploadQuotesAndDocumentExplore from "@/components/modals/forms/UploadQuotesAndDocumentExplore.vue";
import { statusModal, notif } from "@/store/stateless/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Mutations } from "@/store/enums/StoreEnums";
import MasterService from "@/core/services/car/MasterService";
import mainSocket from "./../../../main-socket";
import ReusableModal from '@/components/modals/forms/ReusableModal.vue';

interface IPayload {
  per_page: number | string;
  page: number;
  reload: boolean
}

interface IDocBodyParam {
  lead_id: any;
  quote_request_id: any;
  status: any;
}

export interface IInsurancePolicies {
  id: number;
  insurance_provider_id: number;
  policy_name: string;
  short_name: string;
  policy_type: number;
  policy_sub_type: number;
  created_at: string;
  updated_at: string;
  status: number;
  policy_type_text: string;
}

export default defineComponent({
  // component name
  name: "quote-requests",
  components: {
    // PolicyDocumentModal,
    // QuoteRequestDocumentModal,
    UploadQuotesAndDocumentExplore,
    ReusableModal,
  },
  data() {
    // constants
  },
  setup() {
    const store = useStore();

    let bodyPayloads = ref<IPayload>({
      per_page: 50,
      page: 1,
      reload: true
    });

    let docBodyParam = {} as IDocBodyParam;
    let selectedQuoteReqStatus = ref(false);

    const displayResponseData = computed(() => {
      return store.getters.getquoteRequestList;
    });

    const myPagination = computed(() => {
      return store.getters.getPagination;
    });

    let policyDocuments = computed(() => {
      return store.getters.getTempPolicyDocs;
    });

    let quoteRequestDocuments = computed(() => {
      return store.getters.getQuoteRequestDocument;
    });

    let insuranceProvidersList = computed(() => {
      return store.getters.getInsuranceProvidersList;
    });

    let currentQuoteReqId = computed(() => {
      return store.getters.getQuoteRequestId;
    });

    const getPaginationUrl = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.page = currentPage.value;
      QuotesService.callingQuoteRequestListing(bodyPayloads.value);
    };

    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.per_page = event.target.value;
      QuotesService.callingQuoteRequestListing(bodyPayloads.value);
    };

    const getLeadTempDocuments = (lead_id) => {
      docBodyParam.lead_id = lead_id;
      PolicyService.getTempPolicyDocuments(docBodyParam);
      MasterService.getDocumentTypes({
        categories: "standard",
      });
    };

    const getQuoteRequestDocs = (quote_request_id, status) => {
      docBodyParam.quote_request_id = quote_request_id;
      docBodyParam.status = status;
      QuotesService.callingQuoteRequestDocs(docBodyParam);
      MasterService.getInsuranceProviders();
    };

    const uploadQuotes = ref()
    const getQuoteRequestDocsAndTempDocs = (
      quote_request_id,
      lead_id,
      status
    ) => {
      getLeadTempDocuments(lead_id);
      getQuoteRequestDocs(quote_request_id, status);
      if (status == 3) {
        selectedQuoteReqStatus.value = true;
        uploadQuotes.value.uploadTime()
      } else {
        selectedQuoteReqStatus.value = false;
        uploadQuotes.value.closeTime()
      }
    };

    const currentUserId = store.getters.currentUser.id;

    onMounted(async() => {
      setCurrentPageBreadcrumbs("Provide Quotes", []);
      QuotesService.callingQuoteRequestListing(bodyPayloads.value);

      await mainSocket
        .channel("channel-" + currentUserId)
        .listen("ProvideQuoteRequestSocketEvent", async (response) => {
          if (response.status) {
            console.log("notifs");
            bodyPayloads.value.reload = false
            await QuotesService.callingQuoteRequestListing(bodyPayloads.value);
          }
        });
    });

    const currentPage = ref(1);
    let loading = computed(() => {
      return store.getters.getQrLoading;
    });

    const setLoadingTrue = () => {
      store.commit(Mutations.SET_POLICY_DOCUMENT_LOADING, true);
    };

    const rowClickOpen = ({ id, car_lead, status }) => {
      getQuoteRequestDocsAndTempDocs(
        id,
        car_lead.id,
        status
      )
    };

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const itemData = ref(null);
    const isLoadingData = ref(false);
    const isOpen = ref(false);
    const OpenDelete = (id) => {
      itemData.value = id;
      isOpen.value = true;
      focusable.value.toFirst()
    }
    const close = () => {
      itemData.value = null;
      isOpen.value = false;
      isLoadingData.value = false;
      isClicked.value = false;
    }
    
    const isClicked = ref(false);
    const deleteQuoteRequest = async (
      quote_request_id
    ) => {
      if(!isClicked.value) {
        isClicked.value = true;
        await QuotesService.deleteQuoteRequestById({quote_request_id:quote_request_id});
        notif.simple('Please Note', 'success', 'Quote request is deleted successfully');
        QuotesService.callingQuoteRequestListing(bodyPayloads.value);
        close();
      }
    };

    const focusable = ref();
    return {
      uploadQuotes,
      setLoadingTrue,
      currentPage,
      displayResponseData,
      myPagination,
      bodyPayloads,
      onPerPageChange,
      getPaginationUrl,
      getLeadTempDocuments,
      policyDocuments,
      statusModal,
      getQuoteRequestDocs,
      quoteRequestDocuments,
      insuranceProvidersList,
      currentQuoteReqId,
      getQuoteRequestDocsAndTempDocs,
      selectedQuoteReqStatus,
      loading,
      rowClickOpen,
      user,
      OpenDelete,
      close,
      deleteQuoteRequest,
      focusable,
      isLoadingData,
      itemData,
      isOpen,
    };
  },
  methods: {
    indexMethod(index) {
      return (
        this.myPagination.current_page * this.myPagination.per_page -
        this.myPagination.per_page +
        index +
        1
      );
    },
  },
});
